<template>
    <div>
        <v-row style="margin-top: 70px;">
            <v-col cols="12" sm="1" lg="2"></v-col>
            <v-col cols="12" sm="10" lg="4" class="text-logi">
                <h1 class="green-title">Notre mission</h1>
                <p style="text-align: justify;">BioTagSensor pousse les limites actuelles des Dispositifs Médicaux
                    utilisés en santé biomédicale par le développement de micro-implants injectables ou
                    micro-laboratoires multifonction. <br><br>
                    En effet, l’équipe de BioTagSensor, passionnée par les technologies, innove avec talent et audace
                    sur la R&D et sur l’intégration de plusieurs technologies comme l’électronique, le traitement du
                    signal, l’IA, le Cloud, la micro-mécanique pour aboutir à un micro-implant laboratoire
                    multifonction injectable par seringue. </p>
            </v-col>
            <v-col cols="12" sm="1" lg="1"></v-col>
            <v-col cols="12" sm="4" lg="4">
                <div class="img-mission">
                    <img src="../images/medecin_ordi.jpg" alt="cage équipée">
                </div>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12" sm="1" lg="2"></v-col>
            <v-col cols="12" sm="10" lg="4">
                <div class="img-equipe">
                    <img src="../images/coaching-equipe.jpg" alt="cage équipée">
                </div>
            </v-col>
            <v-col cols="12" sm="1" lg="1"></v-col>
            <v-col cols="12" sm="4" lg="4" class="text-logi">
                <h1 class="green-title">Notre equipe</h1>
                <p style="text-align: justify;"> Chez BioTagSensor, chaque
                    personne
                    travaille et partage ensemble les mêmes objectifs : améliorer chaque jour le bien-être de
                    chaque
                    être vivant, renforcer le monde de la science biomédicale et participer à la médecine de
                    demain pour
                    un accès santé à tous ! <br><br>Suivi santé et Innovation biomédicale sont les 2 grandes
                    valeurs de
                    notre mission ! En intégrant technologie de pointe et expertise scientifique, nos implants
                    améliorent significativement la recherche biomédicale et contribue au progrès du bien-être
                    de chaque
                    personnes.
                </p>
            </v-col>
        </v-row>
        <foot />
    </div>
</template>

<script>
import foot from '@/components/Footer.vue'
export default {
    components:{
      foot
    },
}
</script>

<style>

.img-mission{
    margin-top: 50px;
}

.img-mission img{
    width: 90%;
}

.img-equipe img{
    width: 90%;
}

.text-logi h1{
    margin-bottom: 20px;
}
.text-logi h4{
    font-family: 'roboto' sans-serif;
    font-size: 20px;
    color: var(--violet);
    width: 100%;
    margin-bottom: 15px;
}

</style>